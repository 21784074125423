
import SegmentedBar from "../SegmentedBar/SegmentedBar.vue";
import { filter } from "~/composables/useFilter";

import {
  getPropertyArea,
  getPropertyAddress,
  getSqmRentForProperty,
  getTotalRentForProperty,
  getExternalIdForProperty,
  getMonthlyRentForProperty,
  getRentCurrencyForProperty,
} from "~/helpers/portfolio/portfolio-property-helpers";
import {
  getSalesStatusIndex,
  getSalesStatusText,
  getTranslationKeyForSalesStatus,
  SALES_STATUS,
  getSalesStatusValues,
} from "~/helpers/portfolio/portfolio-sales-helpers";
import { TYPES, getTranslationKeyForTypes } from "~/helpers/portfolio/portfolio-property-helpers";

export default {
  components: { SegmentedBar },

  inheritAttrs: false,

  props: {
    properties: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    omitFilters: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      salesStatussesSelected: [],
      typesSelected: [],
      headers: [
        {
          id: "name",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_NAME",
          sortingEnabled: true,
          sortingActive: false,
          sortOrder: "desc",
          sortKey: "name",
        },
        {
          id: "id",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_PROPERTY_NUMBER",
          sortingEnabled: true,
          sortingActive: false,
          sortOrder: "asc",
          sortKey: "id",
          sortRelation: "external_id",
        },
        {
          id: "type",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_USAGE",
          sortingEnabled: true,
          sortingActive: false,
          sortOrder: "desc",
          sortKey: "usage",
        },
        {
          id: "area",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_AREA",
          sortingEnabled: false,
          textAlignEnd: true,
        },
        {
          id: "takeoverDate",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_TAKEOVER_DATE",
          sortingEnabled: true,
          sortingActive: false,
          sortOrder: "desc",
          sortKey: "ownership_start_date",
        },
        {
          id: "salesStatus",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_SALES_STATUS",
          sortingEnabled: true,
          sortingActive: false,
          sortOrder: "desc",
          sortKey: "status",
        },
        {
          id: "rentPerMonth",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_RENT_MONTH",
          sortingEnabled: false,
          textAlignEnd: true,
        },
        {
          id: "rentPerYear",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_RENT_YEAR",
          sortingEnabled: false,
          textAlignEnd: true,
        },
        {
          id: "rentPerSqm",
          labelKey: "PORTFOLIO_TABLE_PROPERTIES_AVERAGE_RENT_M2",
          sortingEnabled: false,
          textAlignEnd: true,
        },
      ],
    };
  },

  computed: {
    filter() {
      return filter;
    },

    areaUnit() {
      const property = this.properties[0];

      if (!property) return "m2";

      return getPropertyArea(property).areaUnit ?? "m2";
    },

    areaUnitLocal() {
      return this.$t(`UNITS_${this.areaUnit.toUpperCase()}`);
    },

    emptyState() {
      return this.properties.length === 0;
    },

    salesStatusses() {
      return Object.entries(SALES_STATUS).map((key, value) => {
        return {
          value: value,
          key: this.$t(getTranslationKeyForSalesStatus(key[0])),
          checked: this.salesStatussesSelected.includes(key),
        };
      });
    },

    types() {
      return Object.entries(TYPES).map((key, value) => {
        return {
          value: value,
          key: this.$t(getTranslationKeyForTypes(key[1])),
          checked: this.typesSelected.includes(key),
        };
      });
    },
  },

  watch: {
    salesStatussesSelected(val) {
      this.$emit("filter-sales-status", val);
    },

    typesSelected(val) {
      this.$emit("filter-types", val);
    },
  },

  methods: {
    navigateToProperty(id) {
      const properties = {
        hierarchy: "property",
        page: "overview",
      };

      this.$amplitude.navigateToTab(properties);

      this.$router.push({
        name: "portfolio-property-propertyId",
        params: {
          propertyId: id,
        },
      });
    },

    getAddress(property) {
      return getPropertyAddress(property);
    },

    getYearlyRent(property) {
      return getTotalRentForProperty(property);
    },

    getMonthlyRent(property) {
      return getMonthlyRentForProperty(property);
    },

    getExternalId(property) {
      return getExternalIdForProperty(property);
    },

    getArea(property) {
      const { area } = getPropertyArea(property, this.areaUnit);

      return this.filter.number(Math.round(area));
    },

    getSqmRent(property) {
      return getSqmRentForProperty(property, this.areaUnit).rent;
    },

    getSalesStatus(property) {
      return getSalesStatusIndex(property.status);
    },

    sort(column) {
      this.headers.forEach((header) => {
        if (header.id === column.id) {
          if (header.sortingActive) {
            if (header.sortOrder == "desc") {
              header.sortOrder = "asc";
            } else {
              header.sortOrder = "desc";
            }
          } else {
            header.sortOrder = "desc";
          }
          header.sortingActive = true;
        } else {
          header.sortingActive = false;
        }
      });

      this.$emit("sort", { sortBy: column.sortKey, sortDirection: column.sortOrder, sortRelation: column.sortRelation });
    },

    getSalesExplanation(property) {
      return getSalesStatusText(property.status);
    },

    salesStatusChange(event) {
      this.salesStatussesSelected = event.map((e) => Object.entries(SALES_STATUS)[e][0]);
    },

    typeStatusChange(event) {
      this.typesSelected = event.map((e) => Object.entries(TYPES)[e][0]);
    },

    getSalesStatusValues: getSalesStatusValues,

    filterCurrency(value, property) {
      return this.filter.currency(value, { currency: getRentCurrencyForProperty(property) });
    },

    filterArea(area, areaUnit) {
      return this.filter.area(area, areaUnit);
    },
  },
};
